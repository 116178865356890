<template>
    <v-dialog v-model="localShowDialog" max-width="500px" persistent>
        <v-card>
            <v-card-title class="text-start text-wrap">
                Response Set
            </v-card-title>

            <v-card-text v-if="isDropDown">
                <v-text-field v-model="responseSet.title" label="Title"/>
                <v-text-field v-for="(item, index) in responseSet.items" :key="index"
                              v-model="responseSet.items[index]"
                              append-icon="mdi-delete" autofocus label="Item"
                              @click:append="responseSet.items.splice(index, 1)"/>
                <v-btn color="primary" outlined @click="addItem">Add item</v-btn>
            </v-card-text>

            <v-card-text v-if="isMultiChoice">
                <v-text-field v-model="responseSet.title" dense label="Title" outlined/>
                <v-row align="center" dense justify="start">
                    <v-col class="text-start" cols="3">Label</v-col>
                    <v-col class="text-start" cols="3">Color</v-col>
                    <v-col class="text-start" cols="3">Score</v-col>
                    <v-col class="text-start" cols="3">Negative</v-col>
                    <v-divider/>
                    <v-row v-for="(item, index) in responseSet.items" :key="index">
                        <v-col class="py-0" cols="3">
                            <v-text-field v-model="item.label" autofocus dense label="Item" outlined/>
                        </v-col>
                        <v-col class="py-0" cols="3">
                            <v-menu :close-on-content-click="false" bottom nudge-bottom="40px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" :color="item.color.hex" width="100%"></v-btn>
                                </template>

                                <v-card>
                                    <v-card-text>
                                        <v-color-picker v-model="item.color" hide-canvas hide-inputs
                                                        hide-mode-switch show-swatches/>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </v-col>
                        <v-col class="py-0" cols="3">
                            <v-text-field v-model="item.score" :min="0" dense outlined type="number"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="auto">
                            <v-checkbox v-model="item.negative" class="my-auto"></v-checkbox>
                        </v-col>
                        <v-col class="py-0" cols="1">
                            <v-btn icon @click="responseSet.items.splice(index, 1)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-col>
                        <v-divider class="mb-3"/>
                        <v-btn color="primary" outlined @click="addItem">Add item</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn :disabled="disabled" :loading="loading" color="primary" text @click="confirm">
                    {{ isEditing ? 'Update' : 'Create' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    ADD_INSPECTION_FORM_RESPONSE_SET,
    UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD,
    UPDATE_INSPECTION_FORM_RESPONSE_SET
} from "@/modules/app/store/mutation-types";
import {ResponseType} from "@/modules/forms/enums/response-type";
import {QuestionType} from "@/modules/forms/enums/question-type";

export default {
    name: "KurccResponseSetCreator",
    props: {
        forEditResponseSet: {},
        showDialog: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            required: true
        }
    },
    data() {
        return {
            responseSet: {
                title: null,
                items: []
            },
            emptyMultiChoiceResponseSetItem: {
                label: null,
                color: {
                    hex: '#757575'
                },
                score: 0,
                negative: null
            }
        }
    },
    computed: {
        localShowDialog: {
            get() {
                return this.showDialog
            },
            set(v) {
                this.$emit('update:showDialog', v)
            }
        },
        form() {
            return this.$store.state.forms.form
        },
        getResponseSet() {
            if (this.isDropDown)
                return this.dropdownResponseSet
            if (this.isMultiChoice)
                return this.multipleChoiceResponseSet
            else return null
        },
        dropdownResponseSet() {
            return this.$store.state.forms.form.responseSets.dropdown
        },
        multipleChoiceResponseSet() {
            return this.$store.state.forms.form.responseSets.multiple
        },
        isDropDown() {
            return this.type === ResponseType.Dropdown
        },
        isMultiChoice() {
            return this.type === ResponseType["Multiple choice"]
        },
        isEditing() {
            return !!this.forEditResponseSet
        },
        defaultItem() {
            if (this.isMultiChoice)
                return {...this.emptyMultiChoiceResponseSetItem}
            else return null
        }
    },
    watch: {
        localShowDialog: {
            handler: function (val) {
                if (val && !this.forEditResponseSet) this.resetResponseSet()
            }
        },
        forEditResponseSet: {
            handler: function (val) {
                if (val)
                    this.responseSet = this.$lodash.cloneDeep(this.forEditResponseSet)
            }
        },
    },
    methods: {
        close() {
            this.localShowDialog = false
        },
        cancel() {
            this.close()
            this.$emit('update:forEditResponseSet', undefined)
        },
        confirm() {
            this.responseSet.items = this.$lodash.compact(this.responseSet.items)
            if (this.isEditing) {
                let index = this.$lodash.findIndex(this.getResponseSet, set => this.$lodash.isEqual(set, this.forEditResponseSet))
                this.$store.commit(UPDATE_INSPECTION_FORM_RESPONSE_SET, {set: this.responseSet, type: this.type, index})
                this.updateAllResponseSetReferences()
                this.resetResponseSet()
            } else
                this.$store.commit(ADD_INSPECTION_FORM_RESPONSE_SET, {set: this.responseSet, type: this.type})

            this.close()
        },
        addItem() {
            this.responseSet.items.push(this.$lodash.cloneDeep(this.defaultItem))
        },
        resetResponseSet() {
            this.responseSet = {
                title: null,
                items: [this.$lodash.cloneDeep(this.defaultItem)]
            }
        },
        updateAllResponseSetReferences() {
            for (let pageIndex = 0; pageIndex < this.form.pages.length; pageIndex++) {
                for (let questionIndex = 0; questionIndex < this.form.pages[pageIndex].questions.length; questionIndex++) {
                    if (this.form.pages[pageIndex].questions[questionIndex].type === QuestionType.check) {
                        if (this.form.pages[pageIndex].questions[questionIndex].options.response.type === ResponseType.Dropdown || this.form.pages[pageIndex].questions[questionIndex].options.response.type === ResponseType["Multiple choice"]) {
                            if (this.$lodash.isEqual(this.forEditResponseSet, this.form.pages[pageIndex].questions[questionIndex].options.response.responseSet)) {
                                let response = this.$lodash.cloneDeep(this.form.pages[pageIndex].questions[questionIndex].options.response)
                                response.responseSet = this.responseSet
                                this.$store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD, {
                                    pageIndex,
                                    questionIndex,
                                    field: 'response',
                                    value: response
                                })
                            }
                        }
                    }
                    if (this.form.pages[pageIndex].questions[questionIndex].questions.length) {
                        for (let parentIndex = 0; parentIndex < this.form.pages[pageIndex].questions[questionIndex].questions.length; parentIndex++) {
                            if (this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].type === QuestionType.check) {
                                if (this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].options.response.type === ResponseType.Dropdown || this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].options.response.type === ResponseType["Multiple choice"]) {
                                    if (this.$lodash.isEqual(this.forEditResponseSet, this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].options.response.responseSet)) {
                                        let response = this.$lodash.cloneDeep(this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].options.response)
                                        response.responseSet = this.responseSet
                                        this.$store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD, {
                                            pageIndex,
                                            questionIndex,
                                            parentIndex,
                                            field: 'response',
                                            value: response
                                        })
                                    }
                                }
                            }
                            if (this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions.length) {
                                for (let grandparentIndex = 0; grandparentIndex < this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions.length; grandparentIndex++) {
                                    if (this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions[grandparentIndex].type === QuestionType.check) {
                                        if (this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions[grandparentIndex].options.response.type === ResponseType.Dropdown || this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions[grandparentIndex].options.response.type === ResponseType["Multiple choice"]) {
                                            if (this.$lodash.isEqual(this.forEditResponseSet, this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions[grandparentIndex].options.response.responseSet)) {
                                                let response = this.$lodash.cloneDeep(this.form.pages[pageIndex].questions[questionIndex].questions[parentIndex].questions[grandparentIndex].options.response)
                                                response.responseSet = this.responseSet
                                                this.$store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD, {
                                                    pageIndex,
                                                    questionIndex,
                                                    parentIndex,
                                                    grandparentIndex,
                                                    field: 'response',
                                                    value: response
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.resetResponseSet()
        }
    }
}
</script>
